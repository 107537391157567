import React from 'react'
import MealsSummary from './MealsSummary';
import AvailableMeals from './AvailableMeals';

function Meals() {
  return (
    <>
      <MealsSummary/>
      <AvailableMeals/>
    </>
  )
}

export default Meals
