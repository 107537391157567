import React from 'react';
import HeaderCartButton from './HeaderCartButton';
import mealsImage from '../../assets/takeout.jpg';
import classes from './Header.module.css';

function Header(props) {
  return (
    <>
      <header className={classes.header}>
        <h1>ReactMeals</h1>
        <HeaderCartButton onClick={props.onShowCart} />
      </header>
      <div className={classes['main-image']}>
        <img src={mealsImage} alt="food"></img>
      </div>
    </>
  )
}

export default Header
